import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { useLocation } from "@reach/router";

const PropertyFooter = (props) => {
const location = useLocation();

    return (
      <Container>
        {/* {props.Action && props.Area === "the pearl" && props.Property_Type === "apartments" ? "Hello this is my first pearl property" : props.Action && props.Area === "lusail city" && props.Property_Type === "apartments" ? "Hello Lusail property" : props.Action && props.Area === "doha qatar" ? "Hello first doha qatar property" : 
         <div class="property-bottom-footer">
         <h4>Apartments for rent in Qatar made easy</h4>
         <p>Choosing a new home is one of the most important decisions you can make in life. And if your employer does not provide you with rental accommodation in Qatar, you will need to find the best deal yourself. Essentially, you want to live in a property that you can enjoy all year round, and that is close to everything you could need on a daily basis for the perfect lifestyle in Qatar!</p>
         <p>Fortunately, no one knows Qatar’s rental market quite like our team of real estate experts at The Pearl Gates. We are local and love the area and, above all, we specialise in helping all types of people - from bachelors to large families and discerning clients - find their ideal apartments for rent in Qatar! Our office is located in the heart of Doha, and you are welcome to visit us with any questions you may have regarding renting or contact us online at a convenient time.</p>
         <p>We are pleased to offer a wide selection of properties available to rent in Doha and across Qatar, which can be found above. You can filter results to save time searching for your next home.</p>
         <h4>A one-of-a-kind lifestyle for those who rent apartments in Qatar</h4> 
         <p>Qatar has a population that is largely made up of expatriates. Many decide to rent apartments in Qatar instead of buying - mostly in and around the ever-developing capital city of Doha. This is evident despite the fact that more areas of the country have become an option for property buyers who are foreign to Qatar over time.</p>
         <p>If you are looking to move to a city in the stunning Mediterranean with many career prospects and phenomenal architectural designs around every corner - Doha is the place for you! Not to mention Qatar is the richest country in the world per capita income. Opportunities are endless!</p>
         <p>Residents of the many apartments for rent in Qatar enjoy the countless amenities and essential facilities on offer. For example, the numerous state-of-the-art hospitals that have opened in recent years. In addition to pharmacies, schools for all ages offering British Curriculum courses, and retail and grocery stores.</p>
         <p>Imagine travelling not far from home to find a vast array of restaurants with wonderful cuisines from around the world. There are also endless options for family days out visiting stunning landmarks and vast, lush parks. Or perhaps the ability to effortlessly visit the golf course or a business lounge is more your liking?</p>
         <h4>Apartments for rent in Doha, Qatar built to remarkable standards</h4>
         <p>When searching for a rental home with <a href="https://www.thepearlgates.com/">The Pearl Gates</a>, you can expect a fine selection of properties. Not only apartments for rent in Qatar, but also penthouses, villas on the edge of sparkling beaches, and townhouses and duplexes. Inside these properties are fully-equipped kitchens, modern bathrooms and intricately-designed fixtures and fittings.</p>
         <p>Options are available fully furnished, semi furnished or unfurnished. Fully furnished apartments are a fantastic option for those wishing to stay for only a year or two in Qatar. If longer, you will likely want to add personal touches to your home, and thus a semi or unfurnished apartment will be more suitable.</p>
         <p>When renting, the <a href="https://www.thepearlgates.com/qatar-property-services/landlords/">landlord</a> or developer of the rental property will take care of all maintenance requirements. If you wish to alter fixtures and fittings, this may be possible after discussing your plans with them - the same applies to pets.</p>
         <p>Apartments for rent in Doha, Qatar, are set in state-of-the-art, air conditioned apartment complexes, dotted throughout and around the city of Doha in new, developing communities. They are also typically serviced, including housekeeping and concierge. In addition, a buffet breakfast is offered for an extra charge in some instances.</p>
         <p>Apartment complexes typically have shared:</p>
         <ul>
           <li>Gyms and fitness rooms </li>
           <li>Indoor or outdoor swimming pools and spa facilities</li>
           <li>Children’s playgrounds</li>
           <li>Study, business and/or social areas </li>
           <li>Landscaped gardens</li> 
           <li>Private, covered parking lots</li>  
         </ul>      
         <h4>New communities worth considering?</h4>
         <h5><strong>The Pearl</strong></h5>
         <p>Strategically located within proximity of Doha, The Pearl-Qatar is a man-made island that offers residents a dream lifestyle. Discover yacht-lined marinas, outstanding leisure opportunities, and beautiful fountains and walkways.</p>
         <h5><strong>Lusail City</strong></h5>
         <p>A new city on the edge of the stunning Persian Gulf, which is making great strides for a better environment and a self-sufficient economy, with 19 designated districts for residents to choose from.</p>
         <h5><strong>West Bay and West Bay Lagoon</strong></h5>
         <p>A luxurious area of Doha with many villas, upscale hotels, and magnificent skyscrapers. The Corniche is a show-stopping place to explore, offering a fresh sea breeze and designated areas with free Wi-Fi access.</p>
         <h5><strong>Musharib Downtown</strong></h5>
         <p>A sustainable city project that combines Qatari heritage and architecture with social and environmental innovations, just minutes away from Hamad International Airport.</p>
         <h4>The cost of apartments for rent in Doha, Qatar?</h4>
         <p>Pearl Gates offers rental properties ranging from 6,950 QAR per month for a one-bedroom apartment to 75,000 QAR for a five-bedroom penthouse. The choice is impressive for a wide range of budgets, and there will undoubtedly be a property that matches your tastes and lifestyle goals!</p>
         <p>If you want to live in the country over the long term or are looking for a way to earn money from rental properties - buying could be a much better option. You could benefit from appreciating property value and ROI over time. As such, you can contact us for more details about buying a property in Qatar.</p>
       </div>
} */}
         {location.pathname.indexOf('type-apartments') >= 0 &&
         <div class="property-bottom-footer">
            <h4>Apartments for rent in Qatar made easy</h4>
            <p>Choosing a new home is one of the most important decisions you can make in life. And if your employer does not provide you with rental accommodation in Qatar, you will need to find the best deal yourself. Essentially, you want to live in a property that you can enjoy all year round, and that is close to everything you could need on a daily basis for the perfect lifestyle in Qatar!</p>
            <p>Fortunately, no one knows Qatar’s rental market quite like our team of real estate experts at The Pearl Gates. We are local and love the area and, above all, we specialise in helping all types of people - from bachelors to large families and discerning clients - find their ideal apartments for rent in Qatar! Our office is located in the heart of Doha, and you are welcome to visit us with any questions you may have regarding renting or contact us online at a convenient time.</p>
            <p>We are pleased to offer a wide selection of properties available to rent in Doha and across Qatar, which can be found above. You can filter results to save time searching for your next home.</p>
            <h4>A one-of-a-kind lifestyle for those who rent apartments in Qatar</h4> 
            <p>Qatar has a population that is largely made up of expatriates. Many decide to rent apartments in Qatar instead of buying - mostly in and around the ever-developing capital city of Doha. This is evident despite the fact that more areas of the country have become an option for property buyers who are foreign to Qatar over time.</p>
            <p>If you are looking to move to a city in the stunning Mediterranean with many career prospects and phenomenal architectural designs around every corner - Doha is the place for you! Not to mention Qatar is the richest country in the world per capita income. Opportunities are endless!</p>
            <p>Residents of the many apartments for rent in Qatar enjoy the countless amenities and essential facilities on offer. For example, the numerous state-of-the-art hospitals that have opened in recent years. In addition to pharmacies, schools for all ages offering British Curriculum courses, and retail and grocery stores.</p>
            <p>Imagine travelling not far from home to find a vast array of restaurants with wonderful cuisines from around the world. There are also endless options for family days out visiting stunning landmarks and vast, lush parks. Or perhaps the ability to effortlessly visit the golf course or a business lounge is more your liking?</p>
            <h4>Apartments for rent in Doha, Qatar built to remarkable standards</h4>
            <p>When searching for a rental home with <a href="https://www.thepearlgates.com/">The Pearl Gates</a>, you can expect a fine selection of properties. Not only apartments for rent in Qatar, but also penthouses, villas on the edge of sparkling beaches, and townhouses and duplexes. Inside these properties are fully-equipped kitchens, modern bathrooms and intricately-designed fixtures and fittings.</p>
            <p>Options are available fully furnished, semi furnished or unfurnished. Fully furnished apartments are a fantastic option for those wishing to stay for only a year or two in Qatar. If longer, you will likely want to add personal touches to your home, and thus a semi or unfurnished apartment will be more suitable.</p>
            <p>When renting, the <a href="https://www.thepearlgates.com/qatar-property-services/landlords/">landlord</a> or developer of the rental property will take care of all maintenance requirements. If you wish to alter fixtures and fittings, this may be possible after discussing your plans with them - the same applies to pets.</p>
            <p>Apartments for rent in Doha, Qatar, are set in state-of-the-art, air conditioned apartment complexes, dotted throughout and around the city of Doha in new, developing communities. They are also typically serviced, including housekeeping and concierge. In addition, a buffet breakfast is offered for an extra charge in some instances.</p>
            <p>Apartment complexes typically have shared:</p>
            <ul>
              <li>Gyms and fitness rooms </li>
              <li>Indoor or outdoor swimming pools and spa facilities</li>
              <li>Children’s playgrounds</li>
              <li>Study, business and/or social areas </li>
              <li>Landscaped gardens</li> 
              <li>Private, covered parking lots</li>  
            </ul>      
            <h4>New communities worth considering?</h4>
            <h5><strong>The Pearl</strong></h5>
            <p>Strategically located within proximity of Doha, The Pearl-Qatar is a man-made island that offers residents a dream lifestyle. Discover yacht-lined marinas, outstanding leisure opportunities, and beautiful fountains and walkways.</p>
            <h5><strong>Lusail City</strong></h5>
            <p>A new city on the edge of the stunning Persian Gulf, which is making great strides for a better environment and a self-sufficient economy, with 19 designated districts for residents to choose from.</p>
            <h5><strong>West Bay and West Bay Lagoon</strong></h5>
            <p>A luxurious area of Doha with many villas, upscale hotels, and magnificent skyscrapers. The Corniche is a show-stopping place to explore, offering a fresh sea breeze and designated areas with free Wi-Fi access.</p>
            <h5><strong>Musharib Downtown</strong></h5>
            <p>A sustainable city project that combines Qatari heritage and architecture with social and environmental innovations, just minutes away from Hamad International Airport.</p>
            <h4>The cost of apartments for rent in Doha, Qatar?</h4>
            <p>Pearl Gates offers rental properties ranging from 6,950 QAR per month for a one-bedroom apartment to 75,000 QAR for a five-bedroom penthouse. The choice is impressive for a wide range of budgets, and there will undoubtedly be a property that matches your tastes and lifestyle goals!</p>
            <p>If you want to live in the country over the long term or are looking for a way to earn money from rental properties - buying could be a much better option. You could benefit from appreciating property value and ROI over time. As such, you can contact us for more details about buying a property in Qatar.</p>
          </div>
        }
        {location.pathname.indexOf('type-villas') >= 0 &&
          <div class="property-bottom-footer">
            <h4>Prime villas for rent in Qatar</h4>
            <p>Why rent villas in Qatar with our team of property experts at The Pearl Gates? When choosing your new home, it is important to weigh the pros and cons and determine what is affordable for you. Fortunately, our consultants have helped literally thousands of people move or relocate to Qatar, just like you. We know the market thoroughly and can help you choose the community and villa that resonates the most with your desired lifestyle and ambitions!</p>
            <p>Above you can find our portfolio of available villas for rent in Doha and villas for rent in Qatar. Do filter results to save time searching for a new home. You are welcome to ask us any questions or request information on renting villas in Doha or across Qatar.</p>
            <h4>A vibrant, lavish lifestyle welcomes you</h4>
            <p>You may be one of the thousands of professionals or families who move to Qatar every year and become resident expatriates. If so, it is common and makes sense to find rental accommodation if your employer does not already provide it. Although more areas of Qatar have become available to foreigners to buy properties, renting is undoubtedly a better option if you are on a budget or want to stay for a limited amount of years. </p>
            <p>As you may know, there are bountiful opportunities for career growth in Qatar, especially in the dazzling capital Doha. It is no surprise that Doha continues to win many recognized awards. For example:</p>
            <ul>
              <li>Doha Festival City was named the Best Shopping Destination in Qatar in 2021.</li>
              <li>Educational establishment, ACS DOHA, won the Progress Qatar Award in 2018 for its contribution to high education standards in Qatar.</li>
              <li>The World Travel Awards crowned Hilton Doha as Qatar's Leading City Hotel in 2021.</li>
              <li>Hamad International Airport was awarded the title of Best Airport in the World 2021 by Skytrax World Airport Awards.</li>
            </ul>
            <p>You can thus imagine the phenomenal lifestyle Qatar, and more specifically, Doha offers! Doha merges Qatar's heritage with new infrastructure and environmentally friendly innovations. Villas for Rent in Doha, Qatar, are never far from daily amenities, be it grocery stores, large malls, parks and pharmacies - everything you could expect from a vibrant, developing Arab city!</p>
            <p>Picture the scene - hopping in a taxi, on the metro or in your car to explore the safe streets of Doha City. It is a city with many fine cuisines that will surely satisfy your cravings for food, whether day or night. If you are a fan of history and literature, the Qatar National Library is your place!</p>
            <h4>Where to find villas for rent in Qatar?</h4>
            <p>Most of the villas for rent in Doha and across Qatar are located in new or redesigned communities. The two most popular areas that we can help you explore:</p>
            <h5><strong>The Pearl</strong></h5>
            <p>Once a famous spot for pearl diving, the Pearl-Qatar makes lavish waterfront life a reality. The artificially made island is lined with stunning white sand and filled with leisure and retail spaces.</p>
            <h5><strong>West Bay</strong></h5>
            <p>An impressive neighborhood with a myriad of architectural excellence, entertainment and commercial opportunities. Discover buildings that touch the sky, and relaxing amenities that bring you back to earth.</p>
            <p>Villas for rent in Doha, Qatar, continue to attract mainly families who enjoy a sense of comfort and peace at home, but also the appeal of the urban environment, and never being far from tranquil beaches. The villas themselves are developed to impeccable standards, typically have maid and driver rooms, and have fantastic feng shui and amazing city or sea views. Qatar’s villas are available either furnished, part-furnished or unfurnished, depending on your preference. In addition, either stand alone, semi-detached or part of a shared housing development.</p>
            <p>A fully furnished villa is a solution if you want to stay in Qatar for just one or two years. If longer, a semi or unfurnished villa allows you to put your spin on the interior. The landlord or developer of your rental property will take care of all maintenance needs. If you wish to change fixtures and fittings, this may be possible after discussing plans with them.</p>
            <h4>How much does it cost to rent a villa in Doha, Qatar?</h4>
            <p>The Pearl Gates are pleased to offer a collection of villas at various prices. Examples based on location with monthly costs: West Bay Lagoon 4 bedroom villa at 35,000 AED | Aspire Zone, Al Waab 5 bedroom villa at 12,857 AED | Al Rayyan 4 bedroom villa at 25,000 AED | Al Halil 4 bedroom villa at 14,500 AED | Les Roses 3, Al Waab 5 bedroom villa at 23,000 AED | The Pearl 4 bedroom villa at 23,500 AED.</p>
            <p>As you can see, villas for rent in Qatar with The Pearl Gates are perfect for families and have prices ranging from 12,000 AED to 25,000 + AED.</p>
            <p>If you are interested in staying in Qatar in the long term, buying a villa could be a better option for you. You can also make high returns from your investment, as property figures continue to rise across the country in emerging areas. You are welcome to contact us to discuss this alternative.</p>
          </div>
        }
      </Container>
	)
}
export default PropertyFooter;
